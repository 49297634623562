<template>
  <site-header :dismissible="true" :to="cancelRoute">
    <template v-if="$vuetify.display.smAndUp" #default>
      <strong>{{ $t('signup.fairflat.steps.header.text') }}</strong>
    </template>
    <template #right>
      <progress-steps :num-of-steps="numOfSteps" :current-step="currentStep" @change-step="currentStep = $event" />
    </template>
  </site-header>

  <v-container class="fill-height" max-width="1200">
    <v-fade-transition v-if="currentStep === 1" hide-on-leave appear>
      <set-password-form @submit="onComplete" />
    </v-fade-transition>

    <v-fade-transition v-if="currentStep === 2" hide-on-leave>
      <full-name-form
        :first-name="user.firstName"
        :last-name="user.lastName"
        @cancel="currentStep--"
        @submit="storeName"
      />
    </v-fade-transition>

    <v-fade-transition v-if="currentStep === 3" hide-on-leave>
      <accept-gtc-form :loading="loading" @cancel="currentStep--" @accept="register">
        <div v-if="isInviteFlow">
          <i18n-t keypath="signup.legal.privacy_policy.text">
            <template #link>
              <a :href="$t('global.legal.privacy_policy_link')" target="_blank" rel="noopener">{{
                $t('signup.legal.privacy_policy.link_text')
              }}</a>
            </template>
          </i18n-t>
        </div>
      </accept-gtc-form>
    </v-fade-transition>
  </v-container>
</template>

<script lang="ts">
import AcceptGtcForm from '@/components/forms/AcceptGtcForm.vue'
import FullNameForm from '@/components/forms/FullNameForm.vue'
import SetPasswordForm from '@/components/forms/SetPasswordForm.vue'
import ProgressSteps from '@/components/ProgressSteps.vue'
import SiteHeader from '@/components/SiteHeader.vue'

export default defineComponent({
  components: {
    AcceptGtcForm,
    ProgressSteps,
    SiteHeader,
    FullNameForm,
    SetPasswordForm,
  },
  setup() {
    definePageMeta({
      accessControl: {
        roles: ['unauthenticated'],
      },
    })

    const { registerUser } = useUserStore()
    const { language } = storeToRefs(useLanguageStore())

    const { logError } = useErrorHandling()

    const cancelRoute = computed(() => {
      const token = localStorage.getItem('signup-token')
      if (!token) return { name: 'signup' }
      return {
        name: 'signup-confirm',
        query: { s: token },
      }
    })

    return {
      registerUser,
      language,
      logError,
      cancelRoute,
    }
  },
  data() {
    return {
      isInviteFlow: false,
      currentStep: 1,
      numOfSteps: 3,
      loading: false,
      user: {
        token: '',
        password: '',
        firstName: '',
        lastName: '',
      },
    }
  },
  head() {
    return {
      title: 'Sign Up Steps',
    }
  },
  created() {
    this.validateToken()
    this.checkIfInvite()
  },
  methods: {
    onComplete(password: string) {
      this.user.password = password
      this.goToNextStep()
    },
    checkIfInvite() {
      const isInvite = localStorage.getItem('business-invite')
      if (isInvite) this.isInviteFlow = true
    },
    validateToken() {
      // we need to have a verification token cookie or we cannot complete the registration
      const storedToken = localStorage.getItem('signup-token')
      if (storedToken) {
        this.user.token = storedToken
        // retrieve data from localStorage if set
        // do not store password in token since it will be sent with requests to server

        const storedName = localStorage.getItem('signup-name')
        if (storedName) {
          const parsedName = JSON.parse(storedName) as {
            firstName: string
            lastName: string
          }
          this.user.firstName = parsedName.firstName
          this.user.lastName = parsedName.lastName
        }
      } else {
        // send user to start of registration
        return navigateTo({ name: 'signup' })
      }
    },
    goToNextStep(): void {
      this.currentStep = this.currentStep + 1
    },
    storeName(name: { firstName: string; lastName: string }): void {
      this.user.firstName = name.firstName
      this.user.lastName = name.lastName
      localStorage.setItem(
        'signup-name',
        JSON.stringify({
          firstName: this.user.firstName,
          lastName: this.user.lastName,
        })
      )

      this.goToNextStep()
    },
    async register(): Promise<void> {
      this.loading = true
      try {
        const email = localStorage.getItem('signup-email')
        if (!email) throw new Error('No email found in client storage')

        await this.registerUser(email, {
          ...this.user,
          isGtcAccepted: true,
          language: this.language,
        })

        // clear signup localStorage
        localStorage.removeItem('signup-token')
        localStorage.removeItem('signup-name')
        localStorage.removeItem('signup-email')
        localStorage.removeItem('business-invite')

        // and redirect to /welcome
        // use traditional location.href instead of view router here
        // because the tracking on the welcome page needs a real load
        window.location.href = '/welcome'
      } catch (error) {
        this.logError('Failed to register user', error)
        this.loading = false
      }
    },
  },
})
</script>

<style scoped lang="sass">
.checkbox-links
  margin-top: -25px
  margin-left: 32px

.signup-steps
  +center-content
  +add-focussed-form-layout
  +add-lateral-site-padding
  +add-vertical-main-padding

  &-wrapper
    display: flex
    flex-direction: column
    flex: 1 0 auto

  &__step
    display: flex
    flex-direction: column
    align-items: center

.siteheader
  +max-width
    padding-left: 24px
    padding-right: 24px

.layout-f
  display: flex
  justify-content: center
  align-items: center
  height: 110px
  border-top: 1px solid $c-border
  +max-width
    height: 74px
    padding-left: 30px
    padding-right: 30px

// positioning inside of signup-steps
.progress-steps
  width: 400px
  top: 14px
  +max-width
    top: 10px
</style>
