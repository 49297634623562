<template>
  <site-header :dismissible="currentStep !== 1" :action="() => (currentStep = 1)" :icon-link="currentStep !== 1">
    <template v-if="$vuetify.display.smAndUp" #default>
      <strong>{{ $t('signup.business.steps.header.text') }}</strong>
    </template>
    <template #right>
      <progress-steps :num-of-steps="numOfSteps" :current-step="currentStep" @change-step="currentStep = $event" />
    </template>
  </site-header>

  <v-container class="fill-height">
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular :size="70" color="text" indeterminate />
    </v-row>
    <v-row v-else-if="verificationFailed" align="center">
      <v-col cols="12" md="5" order="2" order-md="1">
        <div class="mb-10">
          <skr-heading level="1" semantic>{{ $t('signup.confirm.error.title') }}</skr-heading>
          <skr-heading level="2">{{ $t('signup.confirm.error.subtitle') }}</skr-heading>
        </div>
        <v-btn :to="{ name: 'business-signup' }" class="ma-0" color="info" size="large">
          {{ $t('signup.confirm.error.cta') }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="7" order="1" order-md="2">
        <responsive-image source="email-link-expired" :width="340" :height="440" />
      </v-col>
    </v-row>
    <template v-else>
      <v-fade-transition v-if="currentStep === 1" hide-on-leave appear>
        <set-password @completed="onComplete" />
      </v-fade-transition>

      <v-fade-transition v-if="currentStep === 2" hide-on-leave appear>
        <business-survey-people @select="selectPeople" @stepBack="currentStep--" />
      </v-fade-transition>

      <v-fade-transition v-if="currentStep === 3" hide-on-leave appear>
        <business-survey-usage @select="selectUsage" @stepBack="currentStep--" />
      </v-fade-transition>

      <v-fade-transition v-if="currentStep === 4" hide-on-leave>
        <company-details
          :company-name="company.name"
          :company-phone="company.phone"
          :company-gtc-accepted="company.gtcAccepted"
          :user-first-name="user.firstName"
          :user-last-name="user.lastName"
          :show-personal-details="true"
          :show-back-button="!loadingSubmit"
          :loading="loadingSubmit"
          @completed="submit($event)"
          @back="currentStep--"
        />
      </v-fade-transition>
    </template>
  </v-container>
</template>

<script lang="ts">
import ProgressSteps from '@/components/ProgressSteps.vue'
import BusinessSurveyPeople from '@/components/signup/BusinessSurveyPeople.vue'
import BusinessSurveyUsage from '@/components/signup/BusinessSurveyUsage.vue'
import CompanyDetails from '@/components/signup/CompanyDetails.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import SetPassword from '@/components/signup/SetPassword.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import useCelloReferralCode from '@/composables/useCelloReferralCode'

export default defineComponent({
  components: {
    SkrHeading,
    ResponsiveImage,
    CompanyDetails,
    SetPassword,
    ProgressSteps,
    SiteHeader,
    BusinessSurveyPeople,
    BusinessSurveyUsage,
  },
  provide: {
    headingBottomPaddingClass: 'pb-7',
  },
  setup() {
    definePageMeta({
      accessControl: {
        roles: ['unauthenticated'],
      },
    })

    const { registerBusiness } = useBusinessStore()
    const { registerUser: register } = useUserStore()
    const widgetStore = useWidgetStore()
    const { language } = storeToRefs(useLanguageStore())
    const { ucc } = useCelloReferralCode()

    const { logError } = useErrorHandling()
    const { userRepository } = useApi()

    return {
      registerBusiness,
      register,
      language,
      logError,
      widgetStore,
      verifyConfirmationToken: userRepository.verifyConfirmationToken,
      ucc,
    }
  },
  data() {
    return {
      company: {
        gtcAccepted: false,
        name: '',
        phone: '',
        attributes: {
          companySize: '',
          skribbleSigningVolume: '',
        },
      },
      currentStep: 1,
      numOfSteps: 4,
      loading: true,
      loadingSubmit: false,
      user: {
        token: '',
        password: '',
        firstName: '',
        lastName: '',
        celloUCC: '',
      },
      verificationFailed: false,
    }
  },
  created() {
    void this.verifyToken()
  },
  methods: {
    onComplete(password: string) {
      this.user.password = password
      this.goToNextStep()
    },
    async verifyToken() {
      // remove any previously set reset-token
      localStorage.removeItem('reset-token')
      // we need to have a verification token cookie, or we cannot complete the registration
      const token = this.$route.query.s?.toString()
      if (token) {
        localStorage.setItem('signup-token', token)
        this.user.token = token
      }

      // retrieve data from localStorage if set
      // do not store password in token since it will be sent with requests to server

      // verify specified token
      try {
        if (!token || typeof token !== 'string') throw new Error('No token provided')
        const result = await this.verifyConfirmationToken(token, this.language)

        // token was already stored before checking its validity
        localStorage.setItem('signup-email', result.email)
        this.loading = false
        this.widgetStore.createSnackbar({
          message: this.$t('business.signup.step.1.snackbar.mail_confirmed'),
        })
      } catch {
        // there was a problem, most likely the token timed out
        // or an invalid token was sent, so display an error
        // remove previously set token in error case
        localStorage.removeItem('signup-token')
        this.verificationFailed = true
        this.loading = false
      }
    },
    goToNextStep(): void {
      this.currentStep++
    },
    /**
     * People value comes from an array on the BusinessSurveyPeople component
     * the value is mapped alongside the text for the option so that if
     * text or ordering or values changed, this mapping is preserved
     */
    selectPeople(value: string) {
      this.company.attributes.companySize = value
      this.goToNextStep()
    },
    /**
     * Usage value comes from an array on the BusinessSurveyUsage component
     * the value is mapped alongside the text for the option so that if
     * text or ordering or values changed, this mapping is preserved
     */
    selectUsage(value: string) {
      this.company.attributes.skribbleSigningVolume = value
      this.goToNextStep()
    },

    async submit(submitData: RegisterData): Promise<void> {
      this.company.name = submitData.company.name
      this.user.firstName = submitData.user.firstName
      this.user.lastName = submitData.user.lastName
      this.user.celloUCC = this.ucc
      this.company.phone = submitData.company.phone
      this.company.gtcAccepted = submitData.company.gtcAccepted
      this.loadingSubmit = true
      await this.registerUser()
      try {
        await this.registerBiz()
        navigateTo({
          name: 'business-welcome',
          params: { origin: 'businessSignup' },
        })
      } catch {
        this.loadingSubmit = false
        this.widgetStore.createSnackbar({
          message: this.$t('billing.settings.cc.error.setup'),
        })
      }
    },
    async registerUser(): Promise<void> {
      try {
        const email = localStorage.getItem('signup-email')
        if (!email) throw new Error('No email found in client storage')

        await this.register(email, {
          ...this.user,
          isGtcAccepted: true,
          language: this.language,
        })
      } catch (error) {
        this.logError('Failed to register user', error)
      }
    },
    async registerBiz(): Promise<void> {
      try {
        const email = localStorage.getItem('signup-email')
        if (!email) throw new Error('No email found in client storage')

        await this.registerBusiness({
          ...this.company,
          email,
        })

        // clear signup localStorage
        localStorage.removeItem('signup-token')
        localStorage.removeItem('signup-name')
        localStorage.removeItem('signup-email')
      } catch {
        this.loadingSubmit = false
        this.widgetStore.createSnackbar({
          message: this.$t('global.error.generic_support'),
        })
      }
    },
  },
})
</script>

<style scoped lang="sass">
.checkbox-links
  margin-top: -25px
  margin-left: 32px

.signup-steps
  +center-content
  +add-focussed-form-layout
  +add-lateral-site-padding
  +add-vertical-main-padding

  &-wrapper
    display: flex
    flex-direction: column
    flex: 1 0 auto

  &__step
    display: flex
    flex-direction: column
    align-items: center

.siteheader
  +max-width
    padding-left: 24px
    padding-right: 24px

.layout-f
  display: flex
  justify-content: center
  align-items: center
  height: 110px
  border-top: 1px solid $c-border
  +max-width
    height: 74px
    padding-left: 30px
    padding-right: 30px

// positioning inside of signup-steps
.progress-steps
  width: 400px
  top: 14px
  +max-width
    top: 10px
</style>
