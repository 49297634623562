
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as bye54N42IGZvH5e_45VvChuw47YzWxCWiIt3Zkix__45Nt5P68Meta } from "/opt/build/repo/src/pages/bye.vue?macro=true";
import { default as index3EBbwWtE9NCeEzLI7T0l_xSanHKPhY83Lj7XPjBcsdEMeta } from "/opt/build/repo/src/pages/index.vue?macro=true";
import { default as login3biFRkeiHMM7NODnBJ6EoBFwFIVxun4ZsXKq9hgakcMMeta } from "/opt/build/repo/src/pages/login.vue?macro=true";
import { default as sorryjg6PfHa3upEgA5dsXnqT_sh2odwTdNgJA12EbHdxsPYMeta } from "/opt/build/repo/src/pages/sorry.vue?macro=true";
import { default as logout2z7vVZBsjmhOsCI9n5MZG7CILtgsH1lqw6SIyAkLal0Meta } from "/opt/build/repo/src/pages/logout.vue?macro=true";
import { default as uploadZXZksQEjRmnyybpgvaUPfXu3PwFPvoUGJrgbfsobvQgMeta } from "/opt/build/repo/src/pages/upload.vue?macro=true";
import { default as expiredtiPGfno_IYfH0_h9vRbj33MzQtVcekISsSQxr_2rXK8Meta } from "/opt/build/repo/src/pages/expired.vue?macro=true";
import { default as upgradeXtKWyYoxMvgp4oUXiJJqGKLdBJSTaqKNVZLisp0UFLwMeta } from "/opt/build/repo/src/pages/upgrade.vue?macro=true";
import { default as welcomerIWyeiv9x_3LVuoMCo6XPq24k6r8uX_45aWVA9QhtBGgYMeta } from "/opt/build/repo/src/pages/welcome.vue?macro=true";
import { default as _91_46_46_46slug_93qN6ECyYMOnlsDNWrGff9olmvolyTMhjsDBkE4h97qvMMeta } from "/opt/build/repo/src/pages/[...slug].vue?macro=true";
import { default as ssojOP_45xwEbBRoaZ5Eg0ajCeA_45NyFV2LODihHQiNR9Q3rYMeta } from "/opt/build/repo/src/pages/signup/sso.vue?macro=true";
import { default as indexgZASrgOHYFxbEq1aMFeEEyqnUZRZL6ENNblC1dkgMjQMeta } from "/opt/build/repo/src/pages/signup/index.vue?macro=true";
import { default as stepsrB20TvLXIfpofvqJLGGvD9iFCGtjnd2xr3wn2Y9NynIMeta } from "/opt/build/repo/src/pages/signup/steps.vue?macro=true";
import { default as not_45availableTnIlZj7cFxqaT6VF9bmiWcfWq6ZpwFZQu8B47IVQ9xEMeta } from "/opt/build/repo/src/pages/not-available.vue?macro=true";
import { default as indexYXTQZ_45zzsFCELkHw3xJYA8XXAnJlnw_45fuzHpOxKZa60Meta } from "/opt/build/repo/src/pages/profile/index.vue?macro=true";
import { default as component_45testcLoH07coN_457lTPLWE8c73ier0TT_GGCBAH4zUlNZC5EMeta } from "/opt/build/repo/src/pages/component-test.vue?macro=true";
import { default as cookiesblockedlo9GU73fqroBlPHZF3g8JedsODRmMdIftPQFc6RDidAMeta } from "/opt/build/repo/src/pages/cookiesblocked.vue?macro=true";
import { default as confirmx_45mBKtvCyYwczC_45DAqziy8QDn_45QTDZb3qQX2w8wErSQMeta } from "/opt/build/repo/src/pages/signup/confirm.vue?macro=true";
import { default as confirmX6GqoqeZdSSNedDA3Sd_DtB6H57IPm0WxjjCBBVJbqYMeta } from "/opt/build/repo/src/pages/profile/confirm.vue?macro=true";
import { default as indexdt2g9EWvSGP_yNCYW0SWBzPVXBSk5GfSLpP6mxPFCigMeta } from "/opt/build/repo/src/pages/sso/error/index.vue?macro=true";
import { default as upgradeTvShD2g1fabcZtHKRy_45O1p8FLON9E71mzlxlGG_45iYTgMeta } from "/opt/build/repo/src/pages/business/upgrade.vue?macro=true";
import { default as welcomeiNmjxHj_P66v_451xv_bUyRL7mGpv5QFvYjWFPbPihjyYMeta } from "/opt/build/repo/src/pages/business/welcome.vue?macro=true";
import { default as settings4sHMsTlw_45FSbeT9LO_45K3dKPNpfwpH4ik0v4JKgwdtTYMeta } from "/opt/build/repo/src/pages/profile/settings.vue?macro=true";
import { default as mail_45sentWqIn2M_HwtW4jdXo3gNPPIB7X_45jxEucBa57P29gmqk8Meta } from "/opt/build/repo/src/pages/signup/mail-sent.vue?macro=true";
import { default as activityBVtQyZzL4rT0tcQEqeU3GPcr7OEYa71lhO3C9wcfqioMeta } from "/opt/build/repo/src/pages/business/activity.vue?macro=true";
import { default as settings6RCnYhx6P5hz_7ST6AdScznNtQwdqBcFBC0b8l3fOUcMeta } from "/opt/build/repo/src/pages/business/settings.vue?macro=true";
import { default as indexaj5pHXdQgZabuToU3u9q36Zl4rIf5zNQO8eOi5jY45YMeta } from "/opt/build/repo/src/pages/invite/[id]/index.vue?macro=true";
import { default as indexOcesE_dHZR6L7tCOtOi9IWyRTIUXksdDIUlB1k_kSrQMeta } from "/opt/build/repo/src/pages/sendto/[id]/index.vue?macro=true";
import { default as companyS3rsisw_45oJSV_hyaWHTO4nZuNHDc4UFMqUwuUfs3TpoMeta } from "/opt/build/repo/src/pages/sso/error/company.vue?macro=true";
import { default as indexKlC_4510l_r4W15UxXOVToFLvFdBW_45qb4eaGTkfD5iKikMeta } from "/opt/build/repo/src/pages/view/[srid]/index.vue?macro=true";
import { default as dashboard2DsRp3xTTEPlsxOG9c3VdUiy9IUmVxoca5c1WYwwYl0Meta } from "/opt/build/repo/src/pages/business/dashboard.vue?macro=true";
import { default as newNmbG9qT35dJul7C1N0_45fFiCrOjg7Ibtd2CDdtyi0NNgMeta } from "/opt/build/repo/src/pages/password-reset/new.vue?macro=true";
import { default as indexBaAw0aSLf4I1Gzt9EGfus_45IvzKtX6YsSGJB3ZU8tqtYMeta } from "/opt/build/repo/src/pages/business/seals/index.vue?macro=true";
import { default as indexafPopHfXSYpQtGTzw_qgyX5geD3HR4zV7YtMhnSgIUQMeta } from "/opt/build/repo/src/pages/password-reset/index.vue?macro=true";
import { default as planaB4XZLeu_45RQtri9zVbUto1aDwFKh0WVgptU2ZF7fbGIMeta } from "/opt/build/repo/src/pages/business/billing/plan.vue?macro=true";
import { default as indexjPgVG6SXtovmCXfPBBrtLWJQWgkt0X_45BO4T16PcZxWIMeta } from "/opt/build/repo/src/pages/business/extras/index.vue?macro=true";
import { default as indexrNZXg29CBeDiox9MdJmBeJ7ld_45pRZvXWQgF_45vLZkKRwMeta } from "/opt/build/repo/src/pages/business/signup/index.vue?macro=true";
import { default as stepsAhQ9XSXhlf_f380W0dp8gX9LgEBwJ3cuYsXZ0v1p55YMeta } from "/opt/build/repo/src/pages/business/signup/steps.vue?macro=true";
import { default as indexZGBW1iSnZ_yPEueKswo7yZFT5SY_456E6pSktuQFf_FjIMeta } from "/opt/build/repo/src/pages/business/billing/index.vue?macro=true";
import { default as indextw86733mw0mkHdH1_2qfcOCjAAwOwbymdfzgjHKFA90Meta } from "/opt/build/repo/src/pages/business/members/index.vue?macro=true";
import { default as indexxc5n8XPUNLCyqJrHgCTXcpo1CmBau3C6EIi08qWAEFMMeta } from "/opt/build/repo/src/pages/business/profile/index.vue?macro=true";
import { default as confirmB_oc_45iL4oXHPQ8cB83vCAaEjOv3VG5nGQbVNf5HPAYQMeta } from "/opt/build/repo/src/pages/password-reset/confirm.vue?macro=true";
import { default as api8tZGVRw6rGBL6C9XO8mMTAiKxksbT6PWU087u_452oDswMeta } from "/opt/build/repo/src/pages/business/developers/api.vue?macro=true";
import { default as indexyRhos5LEgTM7MBIhy_45vBgODNN5UDPPgu4XE9rQ7y_45CgMeta } from "/opt/build/repo/src/pages/business/register/index.vue?macro=true";
import { default as change_45passwordfW8ZUky6ysNRNk2sbb3TvpIXQyOW8TFMEVeoikSBy6kMeta } from "/opt/build/repo/src/pages/profile/change-password.vue?macro=true";
import { default as ident_45resethl7Lboh7Il_zcrPrbrWTBBttFgEYFbJ2pBHsv9fZPR0Meta } from "/opt/build/repo/src/pages/super-admin/ident-reset.vue?macro=true";
import { default as payment_wE_45qt1mQjbKcYjGlL58yCSkCL2hqD974PxX2GSgs48Meta } from "/opt/build/repo/src/pages/business/billing/payment.vue?macro=true";
import { default as domainsKCVjFSc1hMeWzDeHITGW7I8C19YNKjwZX9WTBuQ9ipYMeta } from "/opt/build/repo/src/pages/business/members/domains.vue?macro=true";
import { default as mail_45senttjLTUT55BlWf4KGd3PNBvZA1JSA_uH707ivbZ295ZuIMeta } from "/opt/build/repo/src/pages/password-reset/mail-sent.vue?macro=true";
import { default as visual_45signature_VLbhSNj_5ypqcANDnsw1RfZIfwgUPrQ0KGm_459_45DrCwMeta } from "/opt/build/repo/src/pages/profile/visual-signature.vue?macro=true";
import { default as new1CtVHeW_45JliAqKZuW_45CFXMZQNu5oLrgm5Tgm8tm_45NysMeta } from "/opt/build/repo/src/pages/signup/flows/eidplus/new.vue?macro=true";
import { default as indexnqPoc7xHuauqn3yacaBgVJXfTX2weG3j87SR6hFemKAMeta } from "/opt/build/repo/src/pages/view/[id]/[userid]/index.vue?macro=true";
import { default as mail_45sentfEV6ZxZKjH_IF16FTIyKDQpBNGH5lroVIRyRHh8cJMcMeta } from "/opt/build/repo/src/pages/business/signup/mail-sent.vue?macro=true";
import { default as confirmZZZW4Jzicu0GZ_45dxWeI1CGH80_oFet3nLaN0I_5Hs4kMeta } from "/opt/build/repo/src/pages/signup/flows/eidplus/confirm.vue?macro=true";
import { default as aes_45for_45businessWmutt2WzeCIjWCH8UxF3NBx6QMcJYVmz1KlUELBpBLIMeta } from "/opt/build/repo/src/pages/business/profile/aes-for-business.vue?macro=true";
import { default as indexKBf8r_45ANHV0FelOWWeHV_45L9Fb3482dT2lUes85QsQxcMeta } from "/opt/build/repo/src/pages/profile/signature-standards/index.vue?macro=true";
import { default as indexYy20MMDOHnxNDkLtJuTpKBkAtWzvHQsLr7caiAWI2EMMeta } from "/opt/build/repo/src/pages/business/seals/[accountname]/index.vue?macro=true";
import { default as updateulFmIQgKBkt_45UbYoyVgSKmDSg212BM_jYdp2d7XcuhwMeta } from "/opt/build/repo/src/pages/profile/signature-standards/update.vue?macro=true";
import { default as selfJVuWlySsCablX91yCHOAWbxHF97y6iCIKx14ZVnvmloMeta } from "/opt/build/repo/src/pages/direct-sign/identification/eidas/self.vue?macro=true";
import { default as mobile_45idqrWdSzMnkK7Ge_owjM5gyAtEGF5WtJNHZl0___45xjTzcMeta } from "/opt/build/repo/src/pages/profile/signature-standards/mobile-id.vue?macro=true";
import { default as indexFlt_xjNcIw_45RN6CrzAKlmBNxOfiwZFH6r3cIlyEWC3IMeta } from "/opt/build/repo/src/pages/direct-sign/identification/eidas/index.vue?macro=true";
import { default as videob15NyDq5ag_45PcaCFxGrV3tpWlPT6JPXnors5OY3R9rkMeta } from "/opt/build/repo/src/pages/direct-sign/identification/eidas/video.vue?macro=true";
import { default as autoGkgc0vP6ZyMoqE9RAci9zBolDMgdLlZib4lnvjOjYsIMeta } from "/opt/build/repo/src/pages/direct-sign/identification/zertes/auto.vue?macro=true";
import { default as selfhzVguATcvQ8X8Equ3wqHDlNYd5qFM3sllhWrRUJiMWQMeta } from "/opt/build/repo/src/pages/profile/signature-standards/eidas/self.vue?macro=true";
import { default as index6WxY2rASf5MvmSZO3l7KPlbvTYmwhU2BVBvMEF7WxC8Meta } from "/opt/build/repo/src/pages/direct-sign/identification/zertes/index.vue?macro=true";
import { default as videoXmxK78__45HY3cTw_45i7ZNbKPM1a5OR43OZWejIuoF4R1YMeta } from "/opt/build/repo/src/pages/direct-sign/identification/zertes/video.vue?macro=true";
import { default as indexAHZ0bMOmg3ZvKow5XgWItaMEKSOHZAQpWqWo7KWcniwMeta } from "/opt/build/repo/src/pages/profile/signature-standards/eidas/index.vue?macro=true";
import { default as videoZxdtpUv_4RwZ2QhVH1h2D6OwpnVQUoyOSd0Y4yzQbzwMeta } from "/opt/build/repo/src/pages/profile/signature-standards/eidas/video.vue?macro=true";
import { default as legislationBtb2CzEt3S3vb4bcbnUyKY9wbCn6ha17PAMASXAvzkMMeta } from "/opt/build/repo/src/pages/profile/signature-standards/legislation.vue?macro=true";
import { default as autojJAqu_xyZUqpRYWicT8C8NTdmj35jFYi90_zbo9j7MAMeta } from "/opt/build/repo/src/pages/profile/signature-standards/zertes/auto.vue?macro=true";
import { default as index7GVXPsAQ_y5BsKia3fe7QEJ8_TP14ZYoektQcGxcUYoMeta } from "/opt/build/repo/src/pages/profile/signature-standards/zertes/index.vue?macro=true";
import { default as videoTYDxUnOx6UXdIR_453_45Y0KyhiQHH3NQRmGUrcQhqI2eFoMeta } from "/opt/build/repo/src/pages/profile/signature-standards/zertes/video.vue?macro=true";
import { default as id_45austria_45success9wpYwTuutK6Psz0x9th9S0UAxcmUNB2j_THw9LaV4lQMeta } from "/opt/build/repo/src/pages/direct-sign/identification/id-austria-success.vue?macro=true";
import { default as indexyQuu_4526cbypVfRNIIQzQHkuZItC3FUhdbLsdzXVscb4Meta } from "/opt/build/repo/src/pages/direct-sign/identification/[id]/[userid]/index.vue?macro=true";
import { default as id_45austria_45successam2blPpU3JQrLglMiFKneZVt3Cf583v9n_45xGSI1PAUkMeta } from "/opt/build/repo/src/pages/profile/signature-standards/id-austria-success.vue?macro=true";
import { default as previewo2QC2ayWFqDxdiT8DJaAIEtE64YbU62By2RoISw2EiIMeta } from "/opt/build/repo/src/pages/direct-sign/identification/[id]/[userid]/preview.vue?macro=true";
import { default as personal_45details88bx53j7gSziKWQa8A9c_45CQl1cL11hDZ4RJGGDyHijEMeta } from "/opt/build/repo/src/pages/direct-sign/identification/zertes/personal-details.vue?macro=true";
export default [
  {
    name: "bye",
    path: "/bye",
    meta: bye54N42IGZvH5e_45VvChuw47YzWxCWiIt3Zkix__45Nt5P68Meta || {},
    component: () => import("/opt/build/repo/src/pages/bye.vue")
  },
  {
    name: "index",
    path: "/dashboard",
    meta: index3EBbwWtE9NCeEzLI7T0l_xSanHKPhY83Lj7XPjBcsdEMeta || {},
    alias: ["/"],
    component: () => import("/opt/build/repo/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login3biFRkeiHMM7NODnBJ6EoBFwFIVxun4ZsXKq9hgakcMMeta || {},
    component: () => import("/opt/build/repo/src/pages/login.vue")
  },
  {
    name: "sorry",
    path: "/sorry",
    meta: sorryjg6PfHa3upEgA5dsXnqT_sh2odwTdNgJA12EbHdxsPYMeta || {},
    component: () => import("/opt/build/repo/src/pages/sorry.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout2z7vVZBsjmhOsCI9n5MZG7CILtgsH1lqw6SIyAkLal0Meta || {},
    component: () => import("/opt/build/repo/src/pages/logout.vue")
  },
  {
    name: "upload",
    path: "/documents/upload",
    meta: uploadZXZksQEjRmnyybpgvaUPfXu3PwFPvoUGJrgbfsobvQgMeta || {},
    alias: ["/upload"],
    component: () => import("/opt/build/repo/src/pages/upload.vue")
  },
  {
    name: "expired",
    path: "/expired",
    meta: expiredtiPGfno_IYfH0_h9vRbj33MzQtVcekISsSQxr_2rXK8Meta || {},
    component: () => import("/opt/build/repo/src/pages/expired.vue")
  },
  {
    name: "upgrade",
    path: "/upgrade",
    meta: upgradeXtKWyYoxMvgp4oUXiJJqGKLdBJSTaqKNVZLisp0UFLwMeta || {},
    component: () => import("/opt/build/repo/src/pages/upgrade.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomerIWyeiv9x_3LVuoMCo6XPq24k6r8uX_45aWVA9QhtBGgYMeta || {},
    component: () => import("/opt/build/repo/src/pages/welcome.vue")
  },
  {
    name: "404",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93qN6ECyYMOnlsDNWrGff9olmvolyTMhjsDBkE4h97qvMMeta || {},
    component: () => import("/opt/build/repo/src/pages/[...slug].vue")
  },
  {
    name: "signup-sso",
    path: "/signup/sso",
    meta: ssojOP_45xwEbBRoaZ5Eg0ajCeA_45NyFV2LODihHQiNR9Q3rYMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/sso.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexgZASrgOHYFxbEq1aMFeEEyqnUZRZL6ENNblC1dkgMjQMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/index.vue")
  },
  {
    name: "signup-steps",
    path: "/signup/steps",
    meta: stepsrB20TvLXIfpofvqJLGGvD9iFCGtjnd2xr3wn2Y9NynIMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/steps.vue")
  },
  {
    name: "not-available",
    path: "/not-available",
    meta: not_45availableTnIlZj7cFxqaT6VF9bmiWcfWq6ZpwFZQu8B47IVQ9xEMeta || {},
    component: () => import("/opt/build/repo/src/pages/not-available.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexYXTQZ_45zzsFCELkHw3xJYA8XXAnJlnw_45fuzHpOxKZa60Meta || {},
    component: () => import("/opt/build/repo/src/pages/profile/index.vue")
  },
  {
    name: "component-test",
    path: "/component-test",
    component: () => import("/opt/build/repo/src/pages/component-test.vue")
  },
  {
    name: "cookiesblocked",
    path: "/cookiesblocked",
    meta: cookiesblockedlo9GU73fqroBlPHZF3g8JedsODRmMdIftPQFc6RDidAMeta || {},
    component: () => import("/opt/build/repo/src/pages/cookiesblocked.vue")
  },
  {
    name: "signup-confirm",
    path: "/signup/confirm",
    meta: confirmx_45mBKtvCyYwczC_45DAqziy8QDn_45QTDZb3qQX2w8wErSQMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/confirm.vue")
  },
  {
    name: "profile-confirm",
    path: "/profile/confirm",
    meta: confirmX6GqoqeZdSSNedDA3Sd_DtB6H57IPm0WxjjCBBVJbqYMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/confirm.vue")
  },
  {
    name: "sso-error",
    path: "/sso/error",
    meta: indexdt2g9EWvSGP_yNCYW0SWBzPVXBSk5GfSLpP6mxPFCigMeta || {},
    component: () => import("/opt/build/repo/src/pages/sso/error/index.vue")
  },
  {
    name: "business-upgrade",
    path: "/business/upgrade",
    meta: upgradeTvShD2g1fabcZtHKRy_45O1p8FLON9E71mzlxlGG_45iYTgMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/upgrade.vue")
  },
  {
    name: "business-welcome",
    path: "/business/welcome",
    meta: welcomeiNmjxHj_P66v_451xv_bUyRL7mGpv5QFvYjWFPbPihjyYMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/welcome.vue")
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: settings4sHMsTlw_45FSbeT9LO_45K3dKPNpfwpH4ik0v4JKgwdtTYMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/settings.vue")
  },
  {
    name: "signup-mail-sent",
    path: "/signup/mail-sent",
    meta: mail_45sentWqIn2M_HwtW4jdXo3gNPPIB7X_45jxEucBa57P29gmqk8Meta || {},
    component: () => import("/opt/build/repo/src/pages/signup/mail-sent.vue")
  },
  {
    name: "business-activity",
    path: "/business/activity",
    meta: activityBVtQyZzL4rT0tcQEqeU3GPcr7OEYa71lhO3C9wcfqioMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/activity.vue")
  },
  {
    name: "business-settings",
    path: "/business/settings",
    meta: settings6RCnYhx6P5hz_7ST6AdScznNtQwdqBcFBC0b8l3fOUcMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/settings.vue")
  },
  {
    name: "invite-id",
    path: "/invite/:id()",
    meta: indexaj5pHXdQgZabuToU3u9q36Zl4rIf5zNQO8eOi5jY45YMeta || {},
    component: () => import("/opt/build/repo/src/pages/invite/[id]/index.vue")
  },
  {
    name: "sendto-id",
    path: "/sendto/:id()",
    meta: indexOcesE_dHZR6L7tCOtOi9IWyRTIUXksdDIUlB1k_kSrQMeta || {},
    component: () => import("/opt/build/repo/src/pages/sendto/[id]/index.vue")
  },
  {
    name: "sso-error-company",
    path: "/sso/error/company",
    meta: companyS3rsisw_45oJSV_hyaWHTO4nZuNHDc4UFMqUwuUfs3TpoMeta || {},
    component: () => import("/opt/build/repo/src/pages/sso/error/company.vue")
  },
  {
    name: "view-srid",
    path: "/view/:srid()",
    meta: indexKlC_4510l_r4W15UxXOVToFLvFdBW_45qb4eaGTkfD5iKikMeta || {},
    component: () => import("/opt/build/repo/src/pages/view/[srid]/index.vue")
  },
  {
    name: "business-dashboard",
    path: "/business/dashboard",
    meta: dashboard2DsRp3xTTEPlsxOG9c3VdUiy9IUmVxoca5c1WYwwYl0Meta || {},
    component: () => import("/opt/build/repo/src/pages/business/dashboard.vue")
  },
  {
    name: "password-reset-new",
    path: "/password-reset/new",
    meta: newNmbG9qT35dJul7C1N0_45fFiCrOjg7Ibtd2CDdtyi0NNgMeta || {},
    component: () => import("/opt/build/repo/src/pages/password-reset/new.vue")
  },
  {
    name: "business-seals",
    path: "/business/seals",
    meta: indexBaAw0aSLf4I1Gzt9EGfus_45IvzKtX6YsSGJB3ZU8tqtYMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/seals/index.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexafPopHfXSYpQtGTzw_qgyX5geD3HR4zV7YtMhnSgIUQMeta || {},
    component: () => import("/opt/build/repo/src/pages/password-reset/index.vue")
  },
  {
    name: "business-billing-plan",
    path: "/business/billing/plan",
    meta: planaB4XZLeu_45RQtri9zVbUto1aDwFKh0WVgptU2ZF7fbGIMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/billing/plan.vue")
  },
  {
    name: "business-extras",
    path: "/business/extras",
    meta: indexjPgVG6SXtovmCXfPBBrtLWJQWgkt0X_45BO4T16PcZxWIMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/extras/index.vue")
  },
  {
    name: "business-signup",
    path: "/business/signup",
    meta: indexrNZXg29CBeDiox9MdJmBeJ7ld_45pRZvXWQgF_45vLZkKRwMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/signup/index.vue")
  },
  {
    name: "business-signup-steps",
    path: "/business/signup/steps",
    meta: stepsAhQ9XSXhlf_f380W0dp8gX9LgEBwJ3cuYsXZ0v1p55YMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/signup/steps.vue")
  },
  {
    name: "business-billing",
    path: "/business/billing",
    meta: indexZGBW1iSnZ_yPEueKswo7yZFT5SY_456E6pSktuQFf_FjIMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/billing/index.vue")
  },
  {
    name: "business-members",
    path: "/business/members",
    meta: indextw86733mw0mkHdH1_2qfcOCjAAwOwbymdfzgjHKFA90Meta || {},
    component: () => import("/opt/build/repo/src/pages/business/members/index.vue")
  },
  {
    name: "business-profile",
    path: "/business/profile",
    meta: indexxc5n8XPUNLCyqJrHgCTXcpo1CmBau3C6EIi08qWAEFMMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/profile/index.vue")
  },
  {
    name: "password-reset-confirm",
    path: "/password-reset/confirm",
    meta: confirmB_oc_45iL4oXHPQ8cB83vCAaEjOv3VG5nGQbVNf5HPAYQMeta || {},
    component: () => import("/opt/build/repo/src/pages/password-reset/confirm.vue")
  },
  {
    name: "business-developers-api",
    path: "/business/developers/api",
    meta: api8tZGVRw6rGBL6C9XO8mMTAiKxksbT6PWU087u_452oDswMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/developers/api.vue")
  },
  {
    name: "business-register",
    path: "/business/register",
    meta: indexyRhos5LEgTM7MBIhy_45vBgODNN5UDPPgu4XE9rQ7y_45CgMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/register/index.vue")
  },
  {
    name: "profile-change-password",
    path: "/profile/change-password",
    meta: change_45passwordfW8ZUky6ysNRNk2sbb3TvpIXQyOW8TFMEVeoikSBy6kMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/change-password.vue")
  },
  {
    name: "super-admin-ident-reset",
    path: "/super-admin/ident-reset",
    component: () => import("/opt/build/repo/src/pages/super-admin/ident-reset.vue")
  },
  {
    name: "business-billing-payment",
    path: "/business/billing/payment",
    meta: payment_wE_45qt1mQjbKcYjGlL58yCSkCL2hqD974PxX2GSgs48Meta || {},
    component: () => import("/opt/build/repo/src/pages/business/billing/payment.vue")
  },
  {
    name: "business-members-domains",
    path: "/business/members/domains",
    meta: domainsKCVjFSc1hMeWzDeHITGW7I8C19YNKjwZX9WTBuQ9ipYMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/members/domains.vue")
  },
  {
    name: "password-reset-mail-sent",
    path: "/password-reset/mail-sent",
    meta: mail_45senttjLTUT55BlWf4KGd3PNBvZA1JSA_uH707ivbZ295ZuIMeta || {},
    component: () => import("/opt/build/repo/src/pages/password-reset/mail-sent.vue")
  },
  {
    name: "profile-visual-signature",
    path: "/profile/visual-signature",
    meta: visual_45signature_VLbhSNj_5ypqcANDnsw1RfZIfwgUPrQ0KGm_459_45DrCwMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/visual-signature.vue")
  },
  {
    name: "signup-flows-eidplus-new",
    path: "/signup/flows/eidplus/new",
    meta: new1CtVHeW_45JliAqKZuW_45CFXMZQNu5oLrgm5Tgm8tm_45NysMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/flows/eidplus/new.vue")
  },
  {
    name: "view-id-userid",
    path: "/view/:id()/:userid()",
    meta: indexnqPoc7xHuauqn3yacaBgVJXfTX2weG3j87SR6hFemKAMeta || {},
    component: () => import("/opt/build/repo/src/pages/view/[id]/[userid]/index.vue")
  },
  {
    name: "business-signup-mail-sent",
    path: "/business/signup/mail-sent",
    meta: mail_45sentfEV6ZxZKjH_IF16FTIyKDQpBNGH5lroVIRyRHh8cJMcMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/signup/mail-sent.vue")
  },
  {
    name: "signup-flows-eidplus-confirm",
    path: "/signup/flows/eidplus/confirm",
    meta: confirmZZZW4Jzicu0GZ_45dxWeI1CGH80_oFet3nLaN0I_5Hs4kMeta || {},
    component: () => import("/opt/build/repo/src/pages/signup/flows/eidplus/confirm.vue")
  },
  {
    name: "business-profile-aes-for-business",
    path: "/business/profile/aes-for-business",
    meta: aes_45for_45businessWmutt2WzeCIjWCH8UxF3NBx6QMcJYVmz1KlUELBpBLIMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/profile/aes-for-business.vue")
  },
  {
    name: "profile-signature-standards",
    path: "/profile/signature-standards",
    meta: indexKBf8r_45ANHV0FelOWWeHV_45L9Fb3482dT2lUes85QsQxcMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/index.vue")
  },
  {
    name: "business-seals-accountname",
    path: "/business/seals/:accountname()",
    meta: indexYy20MMDOHnxNDkLtJuTpKBkAtWzvHQsLr7caiAWI2EMMeta || {},
    component: () => import("/opt/build/repo/src/pages/business/seals/[accountname]/index.vue")
  },
  {
    name: "profile-signature-standards-update",
    path: "/profile/signature-standards/update",
    meta: updateulFmIQgKBkt_45UbYoyVgSKmDSg212BM_jYdp2d7XcuhwMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/update.vue")
  },
  {
    name: "direct-sign-identification-eidas-self",
    path: "/direct-sign/identification/eidas/self",
    meta: selfJVuWlySsCablX91yCHOAWbxHF97y6iCIKx14ZVnvmloMeta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/eidas/self.vue")
  },
  {
    name: "profile-signature-standards-mobile-id",
    path: "/profile/signature-standards/mobile-id",
    meta: mobile_45idqrWdSzMnkK7Ge_owjM5gyAtEGF5WtJNHZl0___45xjTzcMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/mobile-id.vue")
  },
  {
    name: "direct-sign-identification-eidas",
    path: "/direct-sign/identification/eidas",
    meta: indexFlt_xjNcIw_45RN6CrzAKlmBNxOfiwZFH6r3cIlyEWC3IMeta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/eidas/index.vue")
  },
  {
    name: "direct-sign-identification-eidas-video",
    path: "/direct-sign/identification/eidas/video",
    meta: videob15NyDq5ag_45PcaCFxGrV3tpWlPT6JPXnors5OY3R9rkMeta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/eidas/video.vue")
  },
  {
    name: "direct-sign-identification-zertes-auto",
    path: "/direct-sign/identification/zertes/auto",
    meta: autoGkgc0vP6ZyMoqE9RAci9zBolDMgdLlZib4lnvjOjYsIMeta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/zertes/auto.vue")
  },
  {
    name: "profile-signature-standards-eidas-self",
    path: "/profile/signature-standards/eidas/self",
    meta: selfhzVguATcvQ8X8Equ3wqHDlNYd5qFM3sllhWrRUJiMWQMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/eidas/self.vue")
  },
  {
    name: "direct-sign-identification-zertes",
    path: "/direct-sign/identification/zertes",
    meta: index6WxY2rASf5MvmSZO3l7KPlbvTYmwhU2BVBvMEF7WxC8Meta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/zertes/index.vue")
  },
  {
    name: "direct-sign-identification-zertes-video",
    path: "/direct-sign/identification/zertes/video",
    meta: videoXmxK78__45HY3cTw_45i7ZNbKPM1a5OR43OZWejIuoF4R1YMeta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/zertes/video.vue")
  },
  {
    name: "profile-signature-standards-eidas",
    path: "/profile/signature-standards/eidas",
    meta: indexAHZ0bMOmg3ZvKow5XgWItaMEKSOHZAQpWqWo7KWcniwMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/eidas/index.vue")
  },
  {
    name: "profile-signature-standards-eidas-video",
    path: "/profile/signature-standards/eidas/video",
    meta: videoZxdtpUv_4RwZ2QhVH1h2D6OwpnVQUoyOSd0Y4yzQbzwMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/eidas/video.vue")
  },
  {
    name: "profile-signature-standards-legislation",
    path: "/profile/signature-standards/legislation",
    meta: legislationBtb2CzEt3S3vb4bcbnUyKY9wbCn6ha17PAMASXAvzkMMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/legislation.vue")
  },
  {
    name: "profile-signature-standards-zertes-auto",
    path: "/profile/signature-standards/zertes/auto",
    meta: autojJAqu_xyZUqpRYWicT8C8NTdmj35jFYi90_zbo9j7MAMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/zertes/auto.vue")
  },
  {
    name: "profile-signature-standards-zertes",
    path: "/profile/signature-standards/zertes",
    meta: index7GVXPsAQ_y5BsKia3fe7QEJ8_TP14ZYoektQcGxcUYoMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/zertes/index.vue")
  },
  {
    name: "profile-signature-standards-zertes-video",
    path: "/profile/signature-standards/zertes/video",
    meta: videoTYDxUnOx6UXdIR_453_45Y0KyhiQHH3NQRmGUrcQhqI2eFoMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/zertes/video.vue")
  },
  {
    name: "direct-sign-identification-id-austria-success",
    path: "/direct-sign/identification/id-austria-success",
    meta: id_45austria_45success9wpYwTuutK6Psz0x9th9S0UAxcmUNB2j_THw9LaV4lQMeta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/id-austria-success.vue")
  },
  {
    name: "direct-sign-identification-id-userid",
    path: "/direct-sign/identification/:id()/:userid()",
    meta: indexyQuu_4526cbypVfRNIIQzQHkuZItC3FUhdbLsdzXVscb4Meta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/[id]/[userid]/index.vue")
  },
  {
    name: "profile-signature-standards-id-austria-success",
    path: "/profile/signature-standards/id-austria-success",
    meta: id_45austria_45successam2blPpU3JQrLglMiFKneZVt3Cf583v9n_45xGSI1PAUkMeta || {},
    component: () => import("/opt/build/repo/src/pages/profile/signature-standards/id-austria-success.vue")
  },
  {
    name: "direct-sign-identification-id-userid-preview",
    path: "/direct-sign/identification/:id()/:userid()/preview",
    meta: previewo2QC2ayWFqDxdiT8DJaAIEtE64YbU62By2RoISw2EiIMeta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/[id]/[userid]/preview.vue")
  },
  {
    name: "direct-sign-identification-zertes-personal-details",
    path: "/direct-sign/identification/zertes/personal-details",
    meta: personal_45details88bx53j7gSziKWQa8A9c_45CQl1cL11hDZ4RJGGDyHijEMeta || {},
    component: () => import("/opt/build/repo/src/pages/direct-sign/identification/zertes/personal-details.vue")
  }
]