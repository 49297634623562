<template>
  <div class="identification">
    <toolbar-base :title="toolbarTitle" app />
    <v-main v-if="isLoading" class="fill-height">
      <v-container class="fill-height" fluid>
        <v-row justify="center" align="center">
          <v-progress-circular :size="70" color="text" indeterminate />
        </v-row>
      </v-container>
    </v-main>
    <v-main v-else class="fill-height">
      <v-container class="fill-height identification__content">
        <v-row>
          <v-col
            v-if="identificationStep === 'missingMobile' || identificationStep === 'missingIdent'"
            cols="12"
            md="6"
          >
            <heading
              :title="$t('direct_sign.identification.missing_mobile.title')"
              :subtitle="$t('direct_sign.identification.missing_mobile.subtitle')"
              :hint-title="$t('signature_standards.increase.identification_hint.title')"
              :hint-text="$t('signature_standards.increase.identification_hint.text')"
            />
            <phone-input
              v-model="mobileNumber.value"
              class="mb-4"
              :disabled="!isMobileMutable"
              @phone-number-compliant="isValid => (mobileNumber.isValid = isValid)"
            />
            <v-btn
              size="x-large"
              color="primary"
              block
              data-cy="submitMobileButton"
              :loading="mobileNumber.isLoading"
              :disabled="isMobileMutable && !mobileNumber.isValid"
              @click="submitMobileNumber"
            >
              {{ $t('global.next') }}
            </v-btn>
          </v-col>
          <v-col v-else-if="identificationStep === 'identOngoing'" cols="12" md="6">
            <heading :title="identHeading.title" />
            <ongoing-video-ident-panel v-if="ongoingVideoIdent" @complete="statusPanel.isRefreshPossible = true">
              <template #secondaryAction>
                <v-btn
                  v-if="
                    statusPanel.isRefreshPossible ||
                    (videoIdentStatus && refreshableStatuses.includes(videoIdentStatus))
                  "
                  color="info"
                  variant="outlined"
                  :class="{ 'ml-8': !$vuetify.display.xs }"
                  :block="$vuetify.display.xs"
                  :loading="statusPanel.isLoading"
                  @click="fetchIdentificationInfo('video')"
                >
                  <v-icon start>custom:refresh</v-icon>
                  {{ $t('direct_sign.identification.check_state') }}
                </v-btn>
              </template>
            </ongoing-video-ident-panel>
            <ongoing-self-ident-panel
              v-else-if="eidasOngoingSelfIdent"
              @complete="statusPanel.isRefreshPossible = true"
            >
              <template #secondaryAction>
                <v-btn
                  v-if="statusPanel.isRefreshPossible || refreshableStatuses.includes(eidasSelfIdentStatus ?? '')"
                  color="info"
                  variant="outlined"
                  :class="{ 'ml-8': !$vuetify.display.xs }"
                  :block="$vuetify.display.xs"
                  :loading="statusPanel.isLoading"
                  @click="fetchIdentificationInfo('self')"
                >
                  <v-icon start>custom:refresh</v-icon>
                  {{ $t('direct_sign.identification.check_state') }}
                </v-btn>
              </template>
            </ongoing-self-ident-panel>
            <ongoing-auto-ident-panel
              v-else-if="zertesOngoingAutoIdent"
              @complete="statusPanel.isRefreshPossible = true"
            >
              <template #secondaryAction>
                <v-btn
                  v-if="statusPanel.isRefreshPossible || refreshableStatuses.includes(zertesAutoIdentStatus ?? '')"
                  color="info"
                  variant="outlined"
                  :class="{ 'ml-8': !$vuetify.display.xs }"
                  :block="$vuetify.display.xs"
                  :loading="statusPanel.isLoading"
                  @click="fetchIdentificationInfo('auto')"
                >
                  <v-icon start>custom:refresh</v-icon>
                  {{ $t('direct_sign.identification.check_state') }}
                </v-btn>
              </template>
            </ongoing-auto-ident-panel>
          </v-col>
          <v-col cols="12" md="6">
            <div v-if="activeSignatureRequest && identificationStep !== 'sufficient'" class="preview-wrapper">
              <div v-if="activeDocument" class="d-flex align-center mb-6">
                <v-icon>custom:attachment</v-icon>
                <div class="ml-2">
                  <div class="document-title">
                    {{ activeDocument.title }}
                  </div>
                </div>
              </div>
              <div v-if="$vuetify.display.smAndUp" class="preview">
                <skr-image :src="pagePreviewUrl" @click="openDocumentPreview" />
              </div>
              <v-btn
                :class="{ 'mt-4': $vuetify.display.smAndUp }"
                color="info"
                size="large"
                variant="outlined"
                @click="openDocumentPreview"
              >
                {{ $t('direct_sign.identification.document_preview.view') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts">
import Heading from '@/components/Heading.vue'
import OngoingAutoIdentPanel from '@/components/OngoingAutoIdentPanel.vue'
import OngoingSelfIdentPanel from '@/components/OngoingSelfIdentPanel.vue'
import OngoingVideoIdentPanel from '@/components/OngoingVideoIdentPanel.vue'
import PhoneInput from '@/components/PhoneInput.vue'
import SkrImage from '@/components/SkrImage.vue'
import ToolbarBase from '@/components/ToolbarBase.vue'

type IdentificationStep = 'unknown' | 'sufficient' | 'missingMobile' | 'missingIdent' | 'identOngoing'

export default defineComponent({
  name: 'NoAccountIdentification',
  components: {
    Heading,
    PhoneInput,
    OngoingVideoIdentPanel,
    OngoingSelfIdentPanel,
    ToolbarBase,
    SkrImage,
    OngoingAutoIdentPanel,
  },
  provide: {
    headingBottomPaddingClass: 'pb-10',
  },
  setup() {
    definePageMeta({
      accessControl: {
        roles: ['nas'],
      },
    })

    const route = useRoute()

    const directSignStore = useDirectSignStore()
    const documentStore = useDocumentStore()
    const userStore = useUserStore()
    const widgetStore = useWidgetStore()
    const identStore = useIdentStore()

    const { immutableEidFields } = storeToRefs(directSignStore)
    const { activeSignatureRequest, activeDocument } = storeToRefs(documentStore)
    const { attributes } = storeToRefs(userStore)
    const {
      eidasOngoingIdent,
      zertesOngoingIdent,
      eidasOngoingVideoIdent,
      eidasOngoingSelfIdent,
      eidasSelfIdentStatus,
      eidasVideoIdentStatus,
      zertesOngoingVideoIdent,
      zertesOngoingAutoIdent,
      zertesAutoIdentStatus,
      zertesVideoIdentStatus,
    } = storeToRefs(identStore)

    const { t } = useI18n()

    const { documentPagePreviewURL } = useDocumentUrl()

    const pagePreviewUrl = computed(() => documentPagePreviewURL(activeDocument.value.id, 0, 20))

    const nasData = computed(() => {
      return {
        srId: typeof route.params.id === 'string' ? route.params.id : '',
        userId: typeof route.params.userid === 'string' ? route.params.userid : '',
      }
    })

    const isMobileSet = computed(() => {
      return Boolean(attributes.value.mobile?.length)
    })

    const mobileNumber = reactive({
      value: attributes.value.mobile?.[0] ?? '',
      isValid: false,
      isLoading: false,
    })

    const submitMobileNumber = async () => {
      mobileNumber.isLoading = true

      if (isMobileSet.value && mobileNumber.value === attributes.value.mobile?.[0]) {
        mobileNumber.isLoading = false
        // We already know about the phone number, so we can skip the update
        return goToIdent()
      }

      const previousStep = identificationStep.value

      await directSignStore.update({ mobile: mobileNumber.value })

      if (!['sufficient', 'missingIdent'].includes(identificationStep.value)) {
        widgetStore.createSnackbar({
          message: t('viewer.eid_data.snackbar.aes.message.failure'),
        })
      } else if (previousStep === 'missingIdent' && identificationStep.value === 'missingIdent') {
        // The identification step watcher will not fire when the step remains the same (e.g. when users return
        // to change the mobile number), so we need to check for this separately and redirect when needed
        await goToIdent()
      }

      mobileNumber.isLoading = false
    }

    const { userWillSignSRWith } = useSignatureHelpers()

    const legalWeight = computed(() => {
      return activeSignatureRequest.value.quality.toLowerCase()
    })

    const ongoingIdentification = computed<boolean>(() => {
      return eidasOngoingIdent.value || zertesOngoingIdent.value
    })

    const identificationStep = computed<IdentificationStep>(() => {
      if (userWillSignSRWith.value.quality) {
        return 'sufficient'
      } else {
        switch (legalWeight.value) {
          case 'aes_minimal':
          case 'aes':
            return 'missingMobile'
          case 'qes':
            if (!isMobileSet.value) {
              return 'missingMobile'
            } else if (ongoingIdentification.value) {
              return 'identOngoing'
            } else {
              return 'missingIdent'
            }
          default:
            return 'unknown'
        }
      }
    })

    watch(identificationStep, (newStep: IdentificationStep, previousStep: IdentificationStep) => {
      if (previousStep === 'missingMobile') {
        if (newStep === 'sufficient') {
          widgetStore.createSnackbar({
            message: t('direct_sign.identification.aes.success'),
          })

          return navigateTo({
            replace: true,
            name: 'view-id-userid',
            params: { id: nasData.value.srId, userid: nasData.value.userId },
          })
        } else if (newStep === 'missingIdent') {
          void goToIdent()
        }
      } else if (newStep === 'sufficient') {
        widgetStore.createSnackbar({
          message: t('direct_sign.identification.qes.success'),
        })

        return navigateTo({
          replace: true,
          name: 'view-id-userid',
          params: { id: nasData.value.srId, userid: nasData.value.userId },
        })
      }
    })

    const goToIdent = async () => {
      const legislation = activeSignatureRequest.value.legislation.toLowerCase()

      if (legislation === 'zertes') {
        await navigateTo({ name: 'direct-sign-identification-zertes-personal-details' })
      } else if (legislation === 'eidas') {
        await navigateTo({ name: 'direct-sign-identification-eidas' })
      }
    }

    const { pending: isLoading } = useLazyAsyncData('nasData', async () => {
      await Promise.all([
        documentStore.updateActiveSignatureRequestData(nasData.value.srId),
        identStore.eidasGetAllIdentInfo(),
        identStore.zertesGetAllIdentInfo(),
      ])
    })

    const ongoingVideoIdent = computed(() => {
      return eidasOngoingVideoIdent.value || zertesOngoingVideoIdent.value
    })

    const videoIdentStatus = computed(() => {
      return eidasVideoIdentStatus.value || zertesVideoIdentStatus.value
    })

    const statusPanel = reactive({
      isLoading: false,
      isRefreshPossible: false,
    })

    const fetchIdentificationInfo = async (type: IdentificationMethod) => {
      statusPanel.isLoading = true
      await userStore.fetchUser()
      const legislation = activeSignatureRequest.value.legislation?.toLowerCase()
      if (type === 'video') {
        if (legislation === 'zertes') {
          await identStore.zertesGetVideoIdentInfo()
        } else {
          await identStore.eidasGetVideoIdentInfo()
        }
      } else if (type === 'self') {
        await identStore.eidasGetSelfIdentInfo()
      } else if (type === 'auto') {
        await identStore.zertesGetAutoIdentInfo()
      } else {
        await Promise.all([identStore.eidasGetAllIdentInfo(), identStore.zertesGetAllIdentInfo()])
      }
      statusPanel.isLoading = false
    }

    const openDocumentPreview = async () => {
      await navigateTo(`/direct-sign/identification/${route.params.id}/${route.params.userid}/preview`)
    }

    return {
      fetchIdentificationInfo,
      openDocumentPreview,
      statusPanel,
      ongoingVideoIdent,
      videoIdentStatus,
      eidasOngoingVideoIdent,
      eidasOngoingSelfIdent,
      eidasSelfIdentStatus,
      eidasVideoIdentStatus,
      zertesOngoingVideoIdent,
      zertesOngoingAutoIdent,
      zertesAutoIdentStatus,
      zertesVideoIdentStatus,
      activeSignatureRequest,
      activeDocument,
      fetchUser: userStore.fetchUser,
      nasData,
      mobileNumber,
      isMobileSet,
      immutableEidFields,
      submitMobileNumber,
      legalWeight,
      identificationStep,
      ongoingIdentification,
      goToIdent,
      pagePreviewUrl,
      isLoading,
    }
  },
  data() {
    return {
      refreshableStatuses: ['inprogress', 'activatingQES'],
    }
  },
  computed: {
    isMobileMutable(): boolean {
      return (
        !this.ongoingIdentification &&
        !this.immutableEidFields.includes('all') &&
        !this.immutableEidFields.includes('mobile')
      )
    },
    ongoingIdentStatus(): IdentificationStatus | null {
      return this.ongoingVideoIdent ? (this.videoIdentStatus as IdentificationStatus) : null
    },
    toolbarTitle(): string {
      return ['missingMobile', 'missingIdent'].includes(this.identificationStep)
        ? String(this.$t('direct_sign.identification.enter_mobile'))
        : String(this.$t('direct_sign.identification.identification_required'))
    },
    /**
     * This is the title we want to send to our analytics service.
     */
    documentTitle(): string {
      let title = ''

      if (['missingMobile', 'missingIdent'].includes(this.identificationStep)) {
        title = 'Mobile Input'
      } else if (this.identificationStep === 'identOngoing') {
        if (!this.ongoingIdentStatus) {
          title = 'Unknown'
        } else if (['initialized', 'inprogress'].includes(this.ongoingIdentStatus)) {
          title = 'Status Page (Initialised)'
        } else if (this.ongoingIdentStatus === 'activatingQES') {
          title = 'Status Page (Awaiting ToU Accept)'
        } else if (this.ongoingIdentStatus === 'failure') {
          title = 'Status Page (Failure)'
        }
      } else {
        title = 'Unknown'
      }

      return `NAS Ident - ${title}`
    },
    identHeading(): { title: string } {
      const status = this.ongoingVideoIdent ? (this.videoIdentStatus as string) : 'unknown'

      const statusMap = {
        activatingQES: this.$t('direct_sign.identification.activating_qes.title'),
        failure: this.$t('direct_sign.identification.ident_failed.title'),
        initialized: this.$t('direct_sign.identification.ident_initialized.title'),
        inprogress: this.$t('direct_sign.identification.ident_initialized.title'),
        unknown: this.$t('direct_sign.identification.ident_initialized.title'),
      }

      return { title: String(statusMap[status]) }
    },
  },
  created() {
    if (this.$route.query.primaryAction === 'refresh') {
      this.statusPanel.isRefreshPossible = true
    }
  },
  methods: {
    async goToViewer() {
      await navigateTo({
        replace: true,
        name: 'view-id-userid',
        params: {
          id: this.$route.params.id,
          userid: this.$route.params.userid,
        },
      })
    },
  },
})
</script>

<style lang="sass" scoped>
.identification
  height: 100%
  &__content
    padding: 10rem 4rem
    max-width: $site-width

    .preview-wrapper
      display: flex
      flex-direction: column
      align-items: center
      max-width: min-content
      margin: 0 auto

      .preview
        > img
          cursor: pointer
          @include skribble-box-shadow

      .document-title
        overflow: hidden
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2
</style>
