<template>
  <two-cols-block class="fill-height" split="5/7">
    <template #left>
      <div class="mb-12">
        <skr-heading level="1" semantic>{{ t('eu_ident.self.heading.title') }}</skr-heading>
      </div>
      <p class="mb-10">{{ t('eu_ident.self.requirements.intro') }}</p>
      <ul class="mb-10">
        <li>{{ t('eu_ident.self.requirements.item_1') }}</li>
        <li>{{ t('eu_ident.self.requirements.item_2') }}</li>
        <li>{{ t('eu_ident.self.requirements.item_3') }}</li>
      </ul>
      <p class="mb-10 font-weight-bold">
        {{ t('eu_ident.self.duration_text') }}
      </p>
      <skr-button-group size="xl" stacked>
        <skr-button
          data-cy="start_button"
          :loading="isLoading"
          :disabled="!identUrl"
          :href="identUrl"
          @click="openStatusPage"
          >{{ t('eu_ident.self.start') }}</skr-button
        >
        <skr-button data_cy="cancel_button" type="secondary" :disabled="isLoading" @click="onCancel">{{
          t('unlock_qes.eu.video_ident.start.back')
        }}</skr-button>
      </skr-button-group>
    </template>
    <template v-if="$vuetify.display.mdAndUp" #right>
      <responsive-image source="self-ident" :width="723" :height="697" />
    </template>
  </two-cols-block>
</template>

<script lang="ts">
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import SkrButton from '@/components/button/SkrButton.vue'

export default defineComponent({
  components: {
    SkrButton,
    SkrButtonGroup,
    SkrHeading,
    TwoColsBlock,
    ResponsiveImage,
  },
  setup() {
    definePageMeta({
      layout: 'simple',
      accessControl: {
        roles: ['user'],
      },
    })

    const { t } = useI18n()

    const { email } = storeToRefs(useUserStore())
    const identStore = useIdentStore()
    const { eidasDeleteSelfIdent, eidasGenerateSelfIdent, openStatusPage } = identStore
    const { eidasSelfIdentURL, identInProgress } = storeToRefs(identStore)
    const { srId } = useIdentAvailability('eidas')

    const onCancel = async () => {
      if (eidasSelfIdentURL.value) {
        await eidasDeleteSelfIdent(email.value)
      }
      useRouter().back()
    }

    onBeforeMount(() => {
      void eidasGenerateSelfIdent(srId.value || undefined)
    })

    return {
      t,
      isLoading: identInProgress,
      identUrl: eidasSelfIdentURL,
      openStatusPage,
      onCancel,
    }
  },
  head() {
    return {
      title: 'Profile - Signature Standards - Eidas - Self Ident',
    }
  },
})
</script>
