<script lang="ts">
import type { UploadedUppyFile } from '@uppy/core'

import SkrButton from '@/components/button/SkrButton.vue'
import Heading from '@/components/Heading.vue'
import InviteHeader from '@/components/pages/invite/InviteHeader.vue'
import UploadedFilesList from '@/components/UploadedFilesList.vue'
import UppyUploader from '@/components/UppyUploader.vue'

export default defineComponent({
  components: {
    Heading,
    InviteHeader,
    UppyUploader,
    UploadedFilesList,
    SkrButton,
  },
  setup() {
    definePageMeta({
      path: '/documents/upload',
      alias: '/upload',
      accessControl: {
        // TODO: Tighten access control (after all legacy free users are gone) and remove complimenting middleware
        roles: ['user'],
      },
    })

    const businessStore = useBusinessStore()
    void businessStore.getSettings()

    const userStore = useUserStore()
    const emailVerified = computed(() => userStore.emailVerified)
    const { isNewFreePlan } = useUserPlanInfo()
    const { srRepository } = useApi()

    return { emailVerified, isNewFreePlan, removeSignatureRequest: srRepository.remove }
  },
  data() {
    return {
      uploadList: [] as UploadedFile[],
      maxSimultaneousUploads: 15,
    }
  },
  head() {
    return {
      title: 'Upload',
    }
  },
  computed: {
    ctaText(): string {
      if (this.uploadList.length === 1) {
        return this.$t('global.next')
      } else {
        return this.$t('signature_request.upload.view_uploaded_documents')
      }
    },
    uploadDone(): boolean {
      return this.uploadList.length > 0
    },
  },
  methods: {
    async removeUpload(targetId?: string) {
      if (!targetId) return

      await this.removeSignatureRequest(targetId)

      const index = this.uploadList.findIndex(({ id }) => id === targetId)
      this.uploadList.splice(index, 1)
    },
    title(): string {
      if (this.uploadList.length) {
        return this.$t('signature_request.upload.title_after_upload')
      } else {
        return this.$t('signature_request.upload.title')
      }
    },
    subtitle(): string {
      if (!this.emailVerified) {
        return this.$t('signature_request.upload.unverified_email_text')
      } else {
        if (this.uploadList.length === 1) {
          return this.$t('signature_request.upload.subtitle_after_upload_one')
        }
        if (this.uploadList.length > 1) {
          return this.$t('signature_request.upload.subtitle_after_upload_many')
        }
        return this.$t('signature_request.upload.subtitle')
      }
    },
    nextStep(): void {
      if (this.uploadList.length === 1) {
        void navigateTo({
          name: 'invite-id',
          params: this.uploadList[0].id ? { id: this.uploadList[0].id } : undefined,
        })
      } else {
        void navigateTo({ name: 'index', query: { tab: 'empty' } })
      }
    },
    mapResponseToFileList(
      response: UploadedUppyFile<Record<string, never>, { documentId: string; id: string; title: string }>[]
    ): void {
      this.uploadList = response.map(doc => {
        return {
          id: doc.response?.body.id,
          name: doc.response?.body.title,
          documentId: doc.response?.body.documentId,
        }
      })
    },
  },
})
</script>

<template>
  <invite-header :model-value="1" :steps="4" :disabled="!uploadList.length" @update:model-value="nextStep">
    <template v-if="uploadList.length === 1" #title>
      <strong>{{ $t('signature_request.upload.header_text_one') }}: </strong>{{ uploadList[0].name }}
    </template>
    <template v-else-if="uploadList.length > 1" #title>
      <strong>
        {{ $t('signature_request.upload.header_text_many') }}
      </strong>
    </template>
    <template v-else #title>
      <strong>{{ $t('signature_request.upload.header_text_start') }}</strong>
    </template>
  </invite-header>
  <v-main>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6">
          <heading :center="true" :title="title()" :subtitle="subtitle()" />

          <template v-if="emailVerified">
            <template v-if="!uploadDone">
              <uppy-uploader
                uppy-source="dragAndDrop"
                data-cy="upload_field"
                :allowed-file-types="['.pdf']"
                endpoint="upload"
                :max-simultaneous-uploads="maxSimultaneousUploads"
                @onUploadsComplete="mapResponseToFileList($event)"
              />
              <div class="text-center mt-4 ma-auto">
                <v-icon class="mr-1" color="text">custom:info</v-icon>
                <small>
                  {{
                    $t('signature_request.upload.limit_hint', {
                      n: maxSimultaneousUploads,
                    })
                  }}
                </small>
              </div>
            </template>

            <template v-else>
              <div class="upload-document-list text-center">
                <uploaded-files-list :upload-list="uploadList" @remove="removeUpload" />
              </div>
            </template>
          </template>
          <div v-else class="text-center">
            <skr-button data-cy="profile_button" size="lg" to="/profile">{{
              $t('global.profile.go_to_profile')
            }}</skr-button>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<style lang="sass">
.upload-document-list
  max-width: 420px
  margin: auto
</style>
