<template>
  <two-cols-block class="fill-height" split="5/7">
    <template #left>
      <div class="mb-12">
        <skr-heading level="1" semantic>{{ t('unlock_qes.eu.video_ident.start.title') }}</skr-heading>
      </div>
      <div>
        <p>{{ t('ident.eu.video_ident.setup.text.info') }}</p>
        <p>
          <strong>{{ t('ident.eu.video_ident.setup.text.languages') }}</strong
          ><br />{{ t('ident.eu.video_ident.setup.text.requirements') }}
        </p>
        <p>
          <strong>{{ t('ident.eu.video_ident.setup.list.header') }}</strong>
        </p>
        <ul>
          <li>{{ t('ident.eu.video_ident.setup.list.item_1') }}</li>
          <li>{{ t('ident.eu.video_ident.setup.list.item_2') }}</li>
        </ul>
        <p>
          <strong>{{ t('ident.eu.video_ident.setup.text.activation_header') }}</strong
          ><br />{{ t('ident.eu.video_ident.setup.text.activation') }}
        </p>
      </div>
      <skr-button-group size="xl" stacked>
        <skr-button
          data-cy="start_button"
          :loading="isLoading"
          :disabled="!identUrl"
          :href="identUrl"
          @click="openStatusPage"
          >{{ t('unlock_qes.eu.video_ident.start.start') }}</skr-button
        >
        <skr-button data_cy="cancel_button" type="secondary" :disabled="isLoading" @click="onCancel">{{
          t('unlock_qes.eu.video_ident.start.back')
        }}</skr-button>
      </skr-button-group>
    </template>
    <template v-if="$vuetify.display.mdAndUp" #right>
      <responsive-image :source="`eidas-videoident-steps-${language}`" :width="538" :height="663" />
    </template>
  </two-cols-block>
</template>

<script lang="ts">
import SkrHeading from '@/components/SkrHeading.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrButton from '@/components/button/SkrButton.vue'

export default defineComponent({
  components: {
    SkrButton,
    SkrButtonGroup,
    TwoColsBlock,
    ResponsiveImage,
    SkrHeading,
  },
  setup() {
    definePageMeta({
      layout: 'simple',
      accessControl: {
        roles: ['user'],
      },
    })

    const { t } = useI18n()

    const { email } = storeToRefs(useUserStore())
    const identStore = useIdentStore()
    const { eidasDeleteVideoIdent, eidasGenerateVideoIdent, openStatusPage } = identStore
    const { eidasVideoIdentURL, identInProgress } = storeToRefs(identStore)
    const { language } = storeToRefs(useLanguageStore())
    const { srId } = useIdentAvailability('eidas')

    const onCancel = async () => {
      if (eidasVideoIdentURL.value) {
        await eidasDeleteVideoIdent(email.value)
      }
      useRouter().back()
    }

    onBeforeMount(() => {
      void eidasGenerateVideoIdent(srId.value || undefined)
    })

    return {
      t,
      language,
      isLoading: identInProgress,
      identUrl: eidasVideoIdentURL,
      openStatusPage,
      onCancel,
    }
  },
  head() {
    return {
      title: 'Profile - Signature Standards - Eidas - Video Ident',
    }
  },
})
</script>
