import '@/styles/main.scss'

import type { IconSet, IconProps } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'

// TODO: Refactor our custom icons

import Aadd from '@/components/icons/Aadd.vue'
import AChat from '@/components/icons/AChat.vue'
import AddPerson from '@/components/icons/AddPerson.vue'
import Adelete from '@/components/icons/Adelete.vue'
import AdminGuide from '@/components/icons/AdminGuide.vue'
import AEdit from '@/components/icons/AEdit.vue'
import Archive from '@/components/icons/Archive.vue'
import ArrowUp from '@/components/icons/ArrowUp.vue'
import ArrowDown from '@/components/icons/ArrowDown.vue'
import ArrowFirstPage from '@/components/icons/ArrowFirstPage.vue'
import ArrowLastPage from '@/components/icons/ArrowLastPage.vue'
import ArrowRight from '@/components/icons/ArrowRight.vue'
import Artboard from '@/components/icons/Artboard.vue'
import AStar from '@/components/icons/AStar.vue'
import AStarRemove from '@/components/icons/AStarRemove.vue'
import ASync from '@/components/icons/ASync.vue'
import At from '@/components/icons/At.vue'
import Attachment from '@/components/icons/Attachment.vue'
import AttachmentsSettings from '@/components/icons/AttachmentsSettings.vue'
import AutoDelete from '@/components/icons/AutoDelete.vue'
import Ban from '@/components/icons/Ban.vue'
import BankIdent from '@/components/icons/BankIdent.vue'
import Bell from '@/components/icons/Bell.vue'
import Bin from '@/components/icons/Bin.vue'
import BusinessAccount from '@/components/icons/BusinessAccount.vue'
import BusinessAES from '@/components/icons/BusinessAES.vue'
import BusinessDocs from '@/components/icons/BusinessDocs.vue'
import BusinessProfile from '@/components/icons/BusinessProfile.vue'
import Cached from '@/components/icons/Cached.vue'
import CAdd from '@/components/icons/CAdd.vue'
import CAddFull from '@/components/icons/CAddFull.vue'
import CAddOutline from '@/components/icons/CAddOutline.vue'
import CAddSmall from '@/components/icons/CAddSmall.vue'
import CalendarDate from '@/components/icons/CalendarDate.vue'
import Cancel from '@/components/icons/Cancel.vue'
import CardUpdate from '@/components/icons/CardUpdate.vue'
import CCheck from '@/components/icons/CCheck.vue'
import CClose from '@/components/icons/CClose.vue'
import CheckboxActive from '@/components/icons/CheckboxActive.vue'
import CheckboxInactive from '@/components/icons/CheckboxInactive.vue'
import ChopCircle from '@/components/icons/ChopCircle.vue'
import ChopCircleFull from '@/components/icons/ChopCircleFull.vue'
import CircleDot from '@/components/icons/CircleDot.vue'
import CircleProfile from '@/components/icons/CircleProfile.vue'
import CircleSimpleUp from '@/components/icons/CircleSimpleUp.vue'
import Clear from '@/components/icons/Clear.vue'
import Clock from '@/components/icons/Clock.vue'
import CloseX from '@/components/icons/CloseX.vue'
import ContactEmail from '@/components/icons/ContactEmail.vue'
import CopyContent from '@/components/icons/CopyContent.vue'
import CouponTag from '@/components/icons/CouponTag.vue'
import CQuestion from '@/components/icons/CQuestion.vue'
import CreditCard from '@/components/icons/CreditCard.vue'
import CurrencyExchange from '@/components/icons/CurrencyExchange.vue'
import DataDownload from '@/components/icons/DataDownload.vue'
import Delegate from '@/components/icons/Delegate.vue'
import DelegateUser from '@/components/icons/DelegateUser.vue'
import Design from '@/components/icons/Design.vue'
import Developers from '@/components/icons/Developers.vue'
import Document from '@/components/icons/Document.vue'
import DocumentSearch from '@/components/icons/DocumentSearch.vue'
import Eidplus from '@/components/icons/Eidplus.vue'
import Email from '@/components/icons/Email.vue'
import EmailSettingsAll from '@/components/icons/EmailSettingsAll.vue'
import EmailSettingsImportant from '@/components/icons/EmailSettingsImportant.vue'
import EmailSettingsNone from '@/components/icons/EmailSettingsNone.vue'
import ERemove from '@/components/icons/ERemove.vue'
import ExpandClose from '@/components/icons/ExpandClose.vue'
import ExpandMore from '@/components/icons/ExpandMore.vue'
import ExpandOpen from '@/components/icons/ExpandOpen.vue'
import EZug from '@/components/icons/EZug.vue'
import FDashboard from '@/components/icons/FDashboard.vue'
import FitHeight from '@/components/icons/FitHeight.vue'
import FitWidth from '@/components/icons/FitWidth.vue'
import FolderUser from '@/components/icons/FolderUser.vue'
import Gear from '@/components/icons/Gear.vue'
import Google from '@/components/icons/Google.vue'
import GoToSkribble from '@/components/icons/GoToSkribble.vue'
import HappyEmoticon from '@/components/icons/HappyEmoticon.vue'
import HelpCenter from '@/components/icons/HelpCenter.vue'
import HelpCircle from '@/components/icons/HelpCircle.vue'
import HighlightOff from '@/components/icons/HighlightOff.vue'
import IcMoreHorizontal from '@/components/icons/IcMoreHorizontal.vue'
import IconDummy from '@/components/icons/IconDummy.vue'
import IconVisibility from '@/components/icons/IconVisibility.vue'
import IconVisibilityOff from '@/components/icons/IconVisibilityOff.vue'
import Ident from '@/components/icons/Ident.vue'
import Info from '@/components/icons/Info.vue'
import InfoSolid from '@/components/icons/InfoSolid.vue'
import Integration from '@/components/icons/Integration.vue'
import Invoice from '@/components/icons/Invoice.vue'
import KeyboardArrowDown from '@/components/icons/KeyboardArrowDown.vue'
import Label from '@/components/icons/Label.vue'
import Leave from '@/components/icons/Leave.vue'
import LightningBig from '@/components/icons/LightningBig.vue'
import Loudspeaker from '@/components/icons/Loudspeaker.vue'
import Magnify from '@/components/icons/Magnify.vue'
import Mail from '@/components/icons/Mail.vue'
import Menu from '@/components/icons/Menu.vue'
import Microsoft from '@/components/icons/Microsoft.vue'
import MinusCircle from '@/components/icons/MinusCircle.vue'
import MobileContact from '@/components/icons/MobileContact.vue'
import MoreVert from '@/components/icons/MoreVert.vue'
import Multiple from '@/components/icons/Multiple.vue'
import NeutralEmoticon from '@/components/icons/NeutralEmoticon.vue'
import NewBtnDesktop from '@/components/icons/NewBtnDesktop.vue'
import NewBtnMobile from '@/components/icons/NewBtnMobile.vue'
import Observer from '@/components/icons/Observer.vue'
import Option from '@/components/icons/Option.vue'
import Padlock from '@/components/icons/Padlock.vue'
import PaymentMethod from '@/components/icons/PaymentMethod.vue'
import Pencil from '@/components/icons/Pencil.vue'
import PersonSeveral from '@/components/icons/PersonSeveral.vue'
import PersonSingle from '@/components/icons/PersonSingle.vue'
import Photo from '@/components/icons/Photo.vue'
import RadioButtonActive from '@/components/icons/RadioButtonActive.vue'
import RadioButtonInactive from '@/components/icons/RadioButtonInactive.vue'
import Referral from '@/components/icons/Referral.vue'
import Refresh from '@/components/icons/Refresh.vue'
import Remove from '@/components/icons/Remove.vue'
import Reply from '@/components/icons/Reply.vue'
import Reset from '@/components/icons/Reset.vue'
import RulerPen from '@/components/icons/RulerPen.vue'
import SadEmoticon from '@/components/icons/SadEmoticon.vue'
import Seal from '@/components/icons/Seal.vue'
import Search from '@/components/icons/Search.vue'
import SearchError from '@/components/icons/SearchError.vue'
import SearchNotFound from '@/components/icons/SearchNotFound.vue'
import Security from '@/components/icons/Security.vue'
import SendMessage from '@/components/icons/SendMessage.vue'
import SetupMoney from '@/components/icons/SetupMoney.vue'
import SetupOptions from '@/components/icons/SetupOptions.vue'
import Signature from '@/components/icons/Signature.vue'
import SignatureStandards from '@/components/icons/SignatureStandards.vue'
import SingleCopy from '@/components/icons/SingleCopy.vue'
import SupportHotline from '@/components/icons/SupportHotline.vue'
import Swissid from '@/components/icons/Swissid.vue'
import SystemPreferences from '@/components/icons/SystemPreferences.vue'
import ToSign from '@/components/icons/ToSign.vue'
import TrustId from '@/components/icons/TrustId.vue'
import Ungroup from '@/components/icons/Ungroup.vue'
import VideoIdent from '@/components/icons/VideoIdent.vue'
import VideoTutorials from '@/components/icons/VideoTutorials.vue'
import Wallet from '@/components/icons/Wallet.vue'
import Warning from '@/components/icons/Warning.vue'
import Withdraw from '@/components/icons/Withdraw.vue'
import Zoom from '@/components/icons/Zoom.vue'
import ZoomIn from '@/components/icons/ZoomIn.vue'
import ZoomOut from '@/components/icons/ZoomOut.vue'

/* eslint-disable @typescript-eslint/naming-convention */
const skribbleIcons = {
  dummy: { component: IconDummy },
  a_add: { component: Aadd },
  a_chat: { component: AChat },
  a_delete: { component: Adelete },
  admin_guide: { component: AdminGuide },
  a_edit: { component: AEdit },
  a_star: { component: AStar },
  a_star_remove: { component: AStarRemove },
  a_sync: { component: ASync },
  add_person: { component: AddPerson },
  archive: { component: Archive },
  arrow_up: { component: ArrowUp },
  arrow_down: { component: ArrowDown },
  arrow_right: { component: ArrowRight },
  arrow_first_page: { component: ArrowFirstPage },
  arrow_last_page: { component: ArrowLastPage },
  artboard: { component: Artboard },
  attachment: { component: Attachment },
  attachments_settings: { component: AttachmentsSettings },
  at: { component: At },
  auto_delete: { component: AutoDelete },
  ban: { component: Ban },
  bank_ident: { component: BankIdent },
  bell: { component: Bell },
  bin: { component: Bin },
  business_account: { component: BusinessAccount },
  business_aes: { component: BusinessAES },
  business_docs: { component: BusinessDocs },
  business_profile: { component: BusinessProfile },
  c_add: { component: CAdd },
  c_add_full: { component: CAddFull },
  c_add_outline: { component: CAddOutline },
  c_add_small: { component: CAddSmall },
  cached: { component: Cached },
  calendar_date: { component: CalendarDate },
  cancel: { component: Cancel },
  card_update: { component: CardUpdate },
  c_check: { component: CCheck },
  chop_circle: { component: ChopCircle },
  chop_circle_full: { component: ChopCircleFull },
  c_close: { component: CClose },
  checkbox_active: { component: CheckboxActive },
  checkbox_inactive: { component: CheckboxInactive },
  circle_dot: { component: CircleDot },
  circle_profile: { component: CircleProfile },
  circle_simple_up: { component: CircleSimpleUp },
  clear: { component: Clear },
  clock: { component: Clock },
  close_x: { component: CloseX },
  contact_email: { component: ContactEmail },
  copy_content: { component: CopyContent },
  coupon_tag: { component: CouponTag },
  c_question: { component: CQuestion },
  credit_card: { component: CreditCard },
  currency_exchange: { component: CurrencyExchange },
  data_download: { component: DataDownload },
  delegate: { component: Delegate },
  delegate_user: { component: DelegateUser },
  design: { component: Design },
  developers: { component: Developers },
  document: { component: Document },
  document_search: { component: DocumentSearch },
  eidplus: { component: Eidplus },
  email: { component: Email },
  email_settings_all: { component: EmailSettingsAll },
  email_settings_important: { component: EmailSettingsImportant },
  email_settings_none: { component: EmailSettingsNone },
  e_remove: { component: ERemove },
  expand_close: { component: ExpandClose },
  expand_more: { component: ExpandMore },
  expand_open: { component: ExpandOpen },
  ezug: { component: EZug },
  f_dashboard: { component: FDashboard },
  fit_height: { component: FitHeight },
  fit_width: { component: FitWidth },
  folder_user: { component: FolderUser },
  gear: { component: Gear },
  google: { component: Google },
  go_to_skribble: { component: GoToSkribble },
  happy_emoticon: { component: HappyEmoticon },
  help_center: { component: HelpCenter },
  help_circle: { component: HelpCircle },
  highlight_off: { component: HighlightOff },
  ic_more_horizontal: { component: IcMoreHorizontal },
  ident: { component: Ident },
  info: { component: Info },
  info_solid: { component: InfoSolid },
  integration: { component: Integration },
  invoice: { component: Invoice },
  keyboard_arrow_down: { component: KeyboardArrowDown },
  label: { component: Label },
  leave: { component: Leave },
  lightning_big: { component: LightningBig },
  loudspeaker: { component: Loudspeaker },
  magnify: { component: Magnify },
  mail: { component: Mail },
  menu: { component: Menu },
  microsoft: { component: Microsoft },
  minus_circle: { component: MinusCircle },
  more_vert: { component: MoreVert },
  multiple: { component: Multiple },
  neutral_emoticon: { component: NeutralEmoticon },
  mobile_contact: { component: MobileContact },
  new_btn_desktop: { component: NewBtnDesktop },
  new_btn_mobile: { component: NewBtnMobile },
  observer: { component: Observer },
  option: { component: Option },
  padlock: { component: Padlock },
  payment_method: { component: PaymentMethod },
  pencil: { component: Pencil },
  person_several: { component: PersonSeveral },
  person_single: { component: PersonSingle },
  photo: { component: Photo },
  radio_button_active: { component: RadioButtonActive },
  radio_button_inactive: { component: RadioButtonInactive },
  referral: { component: Referral },
  refresh: { component: Refresh },
  remove: { component: Remove },
  reply: { component: Reply },
  reset: { component: Reset },
  ruler_pen: { component: RulerPen },
  sad_emoticon: { component: SadEmoticon },
  seal: { component: Seal },
  search: { component: Search },
  search_not_found: { component: SearchNotFound },
  search_error: { component: SearchError },
  single_copy: { component: SingleCopy },
  support_hotline: { component: SupportHotline },
  signature: { component: Signature },
  signature_standards: { component: SignatureStandards },
  security: { component: Security },
  send_message: { component: SendMessage },
  setup_money: { component: SetupMoney },
  setup_options: { component: SetupOptions },
  swissid: { component: Swissid },
  system_preferences: { component: SystemPreferences },
  to_sign: { component: ToSign },
  trustid: { component: TrustId },
  ungroup: { component: Ungroup },
  video_ident: { component: VideoIdent },
  video_tutorials: { component: VideoTutorials },
  warning: { component: Warning },
  visibility: { component: IconVisibility },
  visibility_off: { component: IconVisibilityOff },
  wallet: { component: Wallet },
  withdraw: { component: Withdraw },
  zoom: { component: Zoom },
  zoom_in: { component: ZoomIn },
  zoom_out: { component: ZoomOut },
}

const customIcons: IconSet = {
  component: (props: IconProps) =>
    h(typeof props.icon === 'string' ? skribbleIcons[props.icon]?.component : skribbleIcons.dummy.component),
}

export default defineNuxtPlugin({
  name: 'vuetify',
  dependsOn: ['i18n'],
  hooks: {
    'vuetify:before-create'({ vuetifyOptions }) {
      const { $i18nInstance } = useNuxtApp()

      const adapter = createVueI18nAdapter({ i18n: $i18nInstance, useI18n })

      vuetifyOptions.icons = {
        defaultSet: 'mdi',
        sets: { custom: customIcons, mdi },
        aliases,
      }

      vuetifyOptions.locale = {
        adapter,
      }
    },
  },
})
