<script lang="ts" setup>
type ButtonType = 'primary' | 'secondary' | 'text'

export type ButtonColor =
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'ses'
  | 'aes'
  | 'qes'
  | 'part11'
  | 'demo'
  | 'grey'
  | 'grey-dark'
  | 'grey-darker'
  | 'white'

export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl'

export interface ButtonProps {
  type?: ButtonType
  color?: ButtonColor
  size?: ButtonSize
  icon?: string | boolean
  block?: boolean
  disabled?: boolean
  loading?: boolean
  rounded?: string | boolean
  ripple?: boolean
  href?: string
  to?: string | Record<string, unknown>
}

const props = withDefaults(defineProps<ButtonProps>(), {
  type: 'primary',
  size: 'md',
  rounded: 'default',
})

const emit = defineEmits<{
  (e: 'click'): void
}>()

const lookup: { sizes: Record<ButtonSize, string>; variants: Partial<Record<ButtonType, string>> } = {
  sizes: {
    sm: 'small',
    md: 'default',
    lg: 'large',
    xl: 'x-large',
  },
  variants: {
    secondary: 'outlined',
    text: 'text',
  },
}

const inheritedSize = inject<ButtonSize | null>('buttonSize', null)

const computedSize = computed(() => {
  if (inheritedSize) return lookup.sizes[inheritedSize]
  return lookup.sizes[props.size]
})

const computedVariant = computed(() => {
  return lookup.variants[props.type] ?? 'elevated'
})

const computedColor = computed<ButtonColor>(() => {
  if (props.color) return props.color
  if (props.type === 'primary') {
    return 'info'
  } else if (props.type === 'secondary') {
    return 'info'
  } else {
    return 'grey'
  }
})

const textColor = computed(() => {
  if (props.color && ['ses', 'aes', 'qes', 'part11'].includes(props.color)) {
    return 'white'
  }
  return undefined
})

const onClick = () => {
  if (!props.disabled && !props.loading) {
    emit('click')

    if (props.href) {
      window.open(props.href, '_blank', 'noopener')
    } else if (props.to) {
      navigateTo(props.to)
    }
  }
}
</script>

<template>
  <v-btn
    class="skr-button"
    :class="{ 'text-white': textColor === 'white' }"
    :color="computedColor"
    :variant="computedVariant"
    :ripple="ripple"
    :icon="icon"
    :href="href"
    :size="computedSize"
    :rounded="rounded"
    :disabled="disabled"
    :loading="loading"
    :block="block"
    @click="onClick"
  >
    <template v-if="!icon" #default>
      <slot />
    </template>
  </v-btn>
</template>
